<template>
  <div class="words-head">
<!--    <el-form ref="form" :model="form" :rules="rules" class="words-head-add">-->
<!--      <el-form-item prop="keyword">-->
<!--        <el-input type="text" v-model.trim="form.keyword" placeholder="请输入您想添加的关键词">-->
<!--          <template slot="prepend">关键词</template>-->
<!--        </el-input>-->
<!--        <el-button type="success" @click="add">添加</el-button>-->
<!--      </el-form-item>-->
<!--    </el-form>-->
    <el-button type="success" @click="add" v-if="isShow">添加</el-button>
  </div>
</template>

<script>
export default {
  props:{
    isShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      form: { keyword: '' },
      rules: {
        keyword: [
          { required: true, message: '请填写关键词' },
          { pattern: /^[\u4E00-\u9FA5A-Za-z0-9]+$/, message: '关键词应为中文、英文、数字或下划线组成', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    add() {
      // let check = false;
      // this.$refs['form'].validate(res => (check = res));
      // if (check) {
        this.$emit('add', this.form);
        // this.$refs['form'].resetFields();
    //   }
    }
  }
};
</script>

<style lang="scss" scoped>
.words-head {
  padding: 20px;
  text-align: left;
  &-add {
    .el-input {
      width: 200px;
      margin-right: 20px;
    }
  }
}
</style>

